/* .grid will create a grid that is:
 *    6 col on small devices ( < 767px )
 *   12 col on medium devices (768px to 1279px)
 *   14 col on large devices ( >= 1280px)
 * Within a grid, a column can be:
 *   .col - by default, is full width
 *   .leftCol, .rightCol - for splitting content on screens 768px or larger.
 *   .col.super - typically used for the hero slot. full bleed (no margins) for devices less than 1280px.
 * .inset is a special class that is only visually affects XL (1280px) screens.
 *   It is intended to help position content to use the middle 12 columns. This way, content
 *   that is full width on a L screen (12 col) is roughly the same size on an XL screen.
 *   This also prevents lines of text from being too long.
 * .padded_1 is a special class that should only be used with .leftCol/.rightCol
 *   Rather than occupying the entirety of leftCol or rightCol, it is 1 column smaller (leftCol is padded on the right, and vice versa)
 *   This can also be used in conjunction with .inset
 *   Design does this to (1) provide space between columns, and/or (2) not make the column so wide.
 * For more information, see https://github.com/proofxyz/proof/pull/772
 */

.grid {
  display: grid;
  grid-template-columns: repeat(var(--column-count), minmax(0, 1fr));

  /* Common computations */
  --grid-end: calc(var(--column-count) + 1);
  --grid-mid: calc(calc(var(--column-count) / 2) + 1);

  /* Responsive configuration */
  --column-count: 6;
  grid-gap: 3rem 1.5rem;

  @media screen and (min-width: 768px) {
    --column-count: 12;
    grid-gap: 3.5rem 1.5rem;
  }

  @media screen and (min-width: 1280px) {
    --column-count: 14;
    grid-gap: 4.5rem 1.5rem;
  }
}

/* By default a column will use the entire width of the grid */
.col {
  align-self: start;

  grid-column: 1 / var(--grid-end);

  &.inset {
    @media screen and (min-width: 1280px) {
      /* 12 columns wide, inset on both sides */
      grid-column: 2 / var(--column-count);
    }
  }
}

/* For screens 1024 or larger, leftCol and rightCol will be half the width of the grid */
.leftCol {
  grid-column: 1 / var(--grid-end);

  @media screen and (min-width: 1024px) {
    grid-column: 1 / var(--grid-mid);

    &.padded_1 {
      grid-column-end: calc(var(--grid-mid) - 1);
    }
  }

  &.inset {
    @media screen and (min-width: 1280px) {
      grid-column-start: 2;
    }
  }
}

.rightCol {
  grid-column: 1 / var(--grid-end);

  @media screen and (min-width: 1024px) {
    grid-column: var(--grid-mid) / var(--grid-end);

    &.padded_1 {
      grid-column-start: calc(var(--grid-mid) + 1);
    }
  }

  &.inset {
    @media screen and (min-width: 1280px) {
      grid-column-end: var(--column-count);
    }
  }
}

/* .super columns are slightly larger than the 12-column grid. At smaller sizes,
 * they snap to the left and right edges of the viewport.
 */
.col.super {
  margin: 0 calc(var(--Layout--padding-horizontal) * -1);

  @media screen and (min-width: 1280px) {
    margin: 0;
  }

  /* If the super column is the first child, it's assumed to be the hero. This
   * isn't factually correct, but we can assume it to be true for the moment. It
   * will allow us to apply it to previous heros without effort. 😎
   */
  &:first-child {
    margin-top: calc(var(--Content-margin-top) * -1);

    @media screen and (min-width: 1280px) {
      margin-top: 0;
    }
  }
}

/* 
 * Nested grid
 * Useful when the inner-grid needs to be a 12-col grid, and is nested in the middle
 * 12 columns of a 14 col grid.
 */
.grid .grid {
  @media screen and (min-width: 1280px) {
    /* 12-column grid */
    grid-template-columns: repeat(12, minmax(0, 1fr));

    .col {
      /* 12 columns wide */
      grid-column: 1 / 13;
    }

    .leftCol {
      /* 6 columns, starting at 1st column */
      grid-column: 1 / 7;

      &.padded_1 {
        grid-column: 1 / 6;
      }
    }

    .rightCol {
      /* 6 columns, starting at 7th column */
      grid-column: 7 / 13;

      &.padded_1 {
        grid-column: 8 / 13;
      }
    }
  }
}
