.Content {
  @apply mx-auto;
  margin-top: var(--Content-margin-top);
  max-width: var(--Content-max-width);
}

.legal {
  max-width: 768px;
  margin: 0 auto;

  h2,
  p {
    margin-bottom: 1.5rem;
  }

  h3 {
    margin-bottom: 1rem;
  }

  ul {
    list-style-type: disc;
    margin-left: 16px;
  }

  li {
    margin-bottom: 16px;
    color: var(--content-primary);
  }
}
